const api = require('axios');

// 封装的HTTP请求函数
export function httpRequest(method, questUrl, data = null, config = {}) {
    // 根据不同的HTTP方法设置默认的配置

    //let sendUlr = "https://tool.zghbqn.com/platform/front/" + questUrl;

    let sendUlr = "https://api.xyhy.live/platform/front/" + questUrl;

    const defaultConfig = {
        method: method,
        url: sendUlr,
        data: data,
        headers: {
            'Content-Type': 'application/json',
            "ticket": window.localStorage.getItem("ticket")
        }
    };

    // 合并默认配置和用户传入的配置
    const mergedConfig = Object.assign(defaultConfig, config);

    // 发送请求
    return api(mergedConfig)
        .then(response => {
            // 请求成功，返回响应数据
            return response.data;
        })
        .catch(error => {
            // 请求失败，返回错误信息
            console.error(`Error making ${method} request to ${sendUlr}:`, error);
            throw error;
        });
}

// // 使用封装的函数
// httpRequest('GET', 'https://api.example.com/data')
//     .then(data => {
//         console.log('Response data:', data);
//     })
//     .catch(error => {
//         console.error('Request failed:', error);
//     });
//
// httpRequest('POST', 'https://api.example.com/data', { key: 'value' })
//     .then(data => {
//         console.log('Response data:', data);
//     })
//     .catch(error => {
//         console.error('Request failed:', error);
//     });
